<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="6"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩文化展示</div>
        <div class="content2">风雨兼程 掌玩一路有你</div>
      </div>
    </div>
    <div class="page-content">
      <div class="fazhan">
        <!-- <div class="title">领导团队</div>
        <div class="team">
          <div>
            <img src="/ld/mao.jpeg">
            <p class="name">猫老大</p>
            <p class="job">董事长</p>
          </div>
          <div>
            <img src="/ld/luo.jpeg">
            <p class="name">罗涛</p>
            <p class="job">CEO</p>
          </div>
        </div> -->
        <div class="title">文化展示</div>
        <div class="time-line">
          <Timeline
            :timeline-items="dataTimeline"
            :message-when-no-items="messageWhenNoItems"
            :unique-year="true"
            :show-day-and-month="true"
            order="esc"
            @preview="handlePreview"
          />
        </div>
      </div>
    </div>
    <preview-image
      v-model="previewVisible"
      :imgUrl="previewUrl"
    />
    <footer-bar />
  </div>
</template>
<script>
import Timeline from '@/components/TimeLine/Timeline'
import 'timeline-vuejs/dist/timeline-vuejs.css'
import PreviewImage from '@/components/PreviewImage'

export default {
  components: {
    Timeline,
    PreviewImage
  },
  data() {
    return {
      previewVisible: false,
      previewUrl: '',
      messageWhenNoItems: 'There arent items',
      dataTimeline: [
        {
          from: new Date(2014, 5),
          title: '开门迎新春',
          showDayAndMonth: false,
          description: '<img src="/wenhua/new/kmycs.jpeg">'
        },
        {
          from: new Date(2014, 5),
          title: '女生节活动',
          showDayAndMonth: false,
          description: '<img src="/wenhua/new/nsjhd.jpeg">'
        },
        {
          from: new Date(2014, 5),
          title: '亚运在掌玩',
          showDayAndMonth: false,
          description: '<img src="/wenhua/new/zwyyh.jpeg">'
        },
        // {
        //   from: new Date(2014, 5),
        //   title: '梦开始的地方，掌玩成立于和达城小区3楼',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz1.png">'
        // },
        // {
        //   from: new Date(2014, 9),
        //   title: '入职周年',
        //   showDayAndMonth: false,
        //   description:'<img src="/wenhua/wenhua1.png">'
        // },
        // {
        //   from: new Date(2015, 1),
        //   title: '掌玩第一次年会',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz2.jpg">'
        // },
        // {
        //   from: new Date(2015, 5),
        //   title: '节假日福利',
        //   showDayAndMonth: false,
        //   description: '<img src="/wenhua/wenhua2.png">'
        // },
        //  {
        //   from: new Date(2015, 5),
        //   title: '',
        //   showDayAndMonth: false,
        //   description: '<img src="/wenhua/wenhua3.png">'
        // },
        {
          from: new Date(2015, 8),
          title: '下午茶',
          showDayAndMonth: false,
          description: '<img src="/wenhua/wenhua4.png">'
        },
        {
          from: new Date(2015, 8),
          title: '',
          showDayAndMonth: false,
          description: '<img src="/wenhua/wenhua5.png">'
        },
        {
          from: new Date(2015, 10),
          title: '年会',
          showDayAndMonth: false,
          description: '<img src="/wenhua/wenhua6.png">'
        },
        {
          from: new Date(2015, 10),
          title: '',
          showDayAndMonth: false,
          description: '<img src="/wenhua/wenhua7.png">'
        }
        // {
        //   from: new Date(2016, 1),
        //   title: '掌玩2016年年会晚宴',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz4.jpg">'
        // },
        // {
        //   from: new Date(2016, 10),
        //   title: '宁波旅游',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2016/1.jpg">'
        // },
        // {
        //   from: new Date(2017, 3),
        //   title: '掌玩乔迁至东部国际商务中心举办乔迁晚宴',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz5.jpg">'
        // },
        // {
        //   from: new Date(2017, 3),
        //   title: '运营部春游活动',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/1.jpg">'
        // },
        // {
        //   from: new Date(2017, 5),
        //   title: '掌玩郑总和罗涛仅用时30分钟吃了一顿小龙虾就敲定了合作',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz6.jpg">'
        // },
        // {
        //   from: new Date(2017, 8),
        //   title: '大连旅游',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/2.jpg">'
        // },
        // {
        //   from: new Date(2017, 8),
        //   title: '潜龙计划',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/3.jpg">'
        // },
        // {
        //   from: new Date(2017, 8),
        //   title: '塞班旅游',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/4.jpg">'
        // },
        // {
        //   from: new Date(2017, 8),
        //   title: '三亚旅游',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/5.jpg">'
        // },
        // {
        //   from: new Date(2017, 6),
        //   title: '台球比赛',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/6.jpg">'
        // },
        // {
        //   from: new Date(2017, 8),
        //   title: '桐庐团建',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/7.jpg">'
        // },
        // {
        //   from: new Date(2017, 9),
        //   title: '王者荣耀比赛',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/8.jpg">'
        // },
        // {
        //   from: new Date(2017, 9),
        //   title: '周年庆',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2017/9.jpg">'
        // },
        // {
        //   from: new Date(2018, 1),
        //   title: '掌玩2018年掌玩年会',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz7.jpg">'
        // },
        // {
        //   from: new Date(2018, 4),
        //   title: '王成和蒋秀杰加入掌玩，成为合伙人',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz8.jpg">'
        // },
        // {
        //   from: new Date(2018, 8),
        //   title: '千岛湖旅游',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2018/1.jpg">'
        // },
        // {
        //   from: new Date(2018, 5),
        //   title: '周年庆',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2018/3.jpg">'
        // },
        // {
        //   from: new Date(2019, 1),
        //   title: '掌玩2019年年会',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz9.jpg">'
        // },
        // {
        //   from: new Date(2019, 5),
        //   title: '2019“好好学习，天天向上”六一儿童节',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2019/1.jpg">'
        // },
        // {
        //   from: new Date(2019, 5),
        //   title: '第一届端午团队竞赛',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2019/2.jpg">'
        // },
        // {
        //   from: new Date(2019, 6),
        //   title: '运营部表彰大会',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2019/3.jpg">'
        // },
        // {
        //   from: new Date(2020, 1),
        //   title: '掌玩2020年年会',
        //   showDayAndMonth: false,
        //   description: '<img src="/fazhanimg/fz10.jpg">'
        // },
        // {
        //   from: new Date(2020, 5),
        //   title: '2020“无论多大，要相信童话和英雄”六一儿童节',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2020/1.jpg">'
        // },
        // {
        //   from: new Date(2020, 7),
        //   title: '六周年庆',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2020/2.jpg">'
        // },
        // {
        //   from: new Date(2020, 8),
        //   title: '管理层溧阳天目湖团建',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2020/3.jpg">'
        // },
        // {
        //   from: new Date(2020, 8),
        //   title: '前往上沙社区送温暖、慰问老人',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2020/6.jpg">'
        // },
        // {
        //   from: new Date(2020, 9),
        //   title: '掌玩集团经营者计划 第一期',
        //   showDayAndMonth: false,
        //   description: '<img src="/img/2020/5.jpg">'
        // }
      ]
    }
  },
  methods: {
    handlePreview(html) {
      const img = html.split('"')[1].replace('.', '-1.')
      console.log(html, img)

      this.previewUrl = img
      this.previewVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(https://wanxiaomeng-1255977238.cos.ap-shanghai.myqcloud.com/mxy/gw/new.jpg);
  background-position: center center;
  background-size: contain;
}
.page-content {
  display: flex;
  justify-content: center;
}
.fazhan {
  width: 1160px;
  box-sizing: border-box;
  padding: 0 1.5%;
  margin-top: 93px;
  .title {
    font-size: 34px;
    margin-bottom: 70px;
  }
  .team {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 86px;
    & > div {
      padding: 0 10%;
    }
    img {
      width: 248px;
    }
    .name {
      font-size: 24px;
    }
    .job {
      font-size: 18px;
    }
  }
}
.timeline {
  max-width: 900px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}
</style>
<style>
.description-item img {
  width: 80%;
}
</style>